import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createRef, useEffect, useState } from "react";
import logo from "../../assets/logo.webp"
import pic from "../../assets/pic.webp"
import { getData, postData, serverURL } from "../../Services/FetchNodeServices";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useMediaQuery } from "@mui/material";
import {useTheme} from "@mui/material";
import styled,{ keyframes }  from "styled-components";
import { Skeleton } from "@mui/material";





export default function PopularSearches(props) {
    const [banner, setBanner] = useState([])
    var sld = createRef()
    const theme = useTheme();
    const[isShimmer,setIsShimmer]=useState(true)
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one slide at a time
        slidesToScroll: 1, // Scroll one slide at a time
        autoplay: true,
        autoplaySpeed: 3000,
        focusOnSelect: true,
        arrows: false, // Hide arrows to handle them manually
        centerMode: false, //
    };

    const handleForward = () => {
        sld.current.slickPrev()
    }
    const handleBackward = () => {
        sld.current.slickNext()
    }

    //var banners=[{picture:"15.webp,16.webp,17.webp,18.webp,19.webp,20.webp,21.webp"}]
    //var images=Object.values(banners)[0]?.picture.split(",")



    const showBrandSlideShimmer = () => {
        return ["", "", "", "", "", "", ""]?.map((item) => {
          return (
            <div>
              <Skeleton  variant="rectangular" 
                    height={matchesSm ? 200 : 545} 
                    width="100%" 
                    animation="wave">
                <div
                  style={{
                    display: "flex",
                    boxShadow: "1px 1px 10px 0px #00000010",
                  }}
                >
                  <img
                    src={item.photo}
                    style={{
                        width: "100%",
                        maxHeight: matchesSm ? 200 : 545,
                        borderRadius: 30,
                       
                    }}
                  />
                </div>
              </Skeleton>
            </div>
          );
        });
      };

   
    
    
    const showSlide = () => {
        return   banner?.map((item,index) => (
            <div key={index}>
                <img
                    src={item.photo}
                     alt={`Slide ${index}`}
                    style={{
                         width:"100%",
                         height: matchesSm ? 200 : 545,
                          background:'red',
                        
                        display: "block",
                      
                        aspectRatio: 2 / 2
                    }}
                />
            </div>
            
        ))
    }

     // return images?.map((item)=>{
        //     return (<div> <img src={`${serverURL}/images/${item}`} 

    const fetchPopularSearches = async () => {
        const result = await getData('api-banner')
        if (result.status) {
           
            setBanner(result.data)
            setIsShimmer(false)
           
        }
    }

    

    const createSession = async () => {
        const body = { email: "9907026112", password: "12345678" }
        const result = await postData("login", body)
        if (result.token_status) {
            localStorage.setItem("token", result.data.user.jwt_token)
        }
    }

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            createSession().then(() => {
                fetchPopularSearches()
            })
        }
        fetchPopularSearches()
    }, [])


   

   


    return (<div style={{ width: '100%', position: 'relative',height: matchesSm ? 200 : 545}}>
  


{matchesMd?<></>:
        <div style={{ zIndex: 2, top: '45%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: 20, background: '#95a5a6', opacity: 0.6,cursor:'pointer' }}>
            <ArrowBackIosIcon onClick={handleBackward} />
        </div>
}


        <Slider {...settings} ref={sld}>
      
       
                     
        {banner.length ? showSlide() : showBrandSlideShimmer()}
                
        </Slider>



        {matchesMd?<></>:
        <div style={{ zIndex: 2, top: '45%', right: '0.07%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: 20, background: '#95a5a6', opacity: 0.6,cursor:'pointer' }}>
            <ArrowForwardIosIcon onClick={handleForward} />
        </div>
              }

    </div>

    );

}