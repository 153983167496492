import {FormControl,Select,MenuItem,InputLabel,useMediaQuery} from "@mui/material"
import { useTheme } from "@mui/material/styles";

export default function UpperHeader(){
    const theme = useTheme();
    

    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

    return(
    <div style={{width:'100%',height:40,background:'#b4245d',display:'flex',flexDirection:'row'}}>
       <div style={{color:'#fff',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:35,fontSize:matchesMd?'0.7em':<></>}}>India's Favourite Wedding Planning Platform</div>
       
       
    </div>)
}