import {BrowserRouter as Router,Routes,Route, Navigate } from 'react-router-dom'
import Header from './Component/userinterface/Header';
import MainImage from './Component/userinterface/MainImage';
import Home from './Screens/Home';
import Login from './Component/userinterface/Login';
import { useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import Cart from './Screens/Cart';
import PortfolioViewMore from './Screens/PortfolioViewMore';
import ReviewsViewMore from './Screens/ReviewsViewMore';
import React, { useState,  createContext, useContext } from "react";
import CategoryComponent from './Component/userinterface/CategoryComponent';
import WeddingAllVenues from './Screens/WeddingAllVenues';
import AppTesting from './Screens/AppTesting';
import Profile from './Screens/Profile';
import SearchingPage from './Screens/SearchingPage';
import ExtraPage from './Screens/ExtraPage';
import ShopScreen from './Screens/ShopScreen';
import CategoryViewMore from './Screens/CategoryViewMore';
import Registration from './Screens/Registration';



export const AuthContext = createContext();

const PrivateRoute = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    return currentUser ? children : <Navigate to="/login" />;
};




 



function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
        setCurrentUser(JSON.parse(user));
    }
}, []);




  return (
    <div>
       <AuthContext.Provider value={{ currentUser, setCurrentUser }}>

<Router>
          <Routes>
            
          
          <Route element={<Home/>} path={'/home'}/>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route element={<Header/>} path={'/header'}/>
          <Route element={<Login/>} path={'/login'}/>
          <Route element={<Cart/>} path={'/cart/:id'}/>
          <Route element={<PortfolioViewMore/>} path={'/portfolioviewmore'}/>
          <Route element={<ReviewsViewMore/>} path={'/reviewsviewmore'}/>
          <Route element={<CategoryComponent/>} path={'/categorycomponent'}/>
          <Route element={<WeddingAllVenues/>} path={'/weddingallvenues'}/>
          {/* <Route element={<UserProfile/>} path={'/userprofile/:activepage'}/> */}
          {/* <Route element={<WishList/>} path={'/wishlist'}/>
          <Route element={<PreviousOrders/>} path={'/previousorders'}/> */}
          {/* <Route element={<PreviousOrders/>} path={'/previousorders'}/> */}
          <Route element={<Profile/>} path={'/profile/*'}/>
          <Route element={<SearchingPage/>} path={'/searchingpage'}/>
          <Route element={<ExtraPage/>} path={'/extrapage'}/>
          <Route element={<ShopScreen/>} path={'/shopscreen'}/>
         < Route element={<CategoryViewMore/>} path={'/categoryviewmore'}/>
         < Route element={<AppTesting/>} path={'/apptesting'}/>
         < Route element={<Login/>} path={'/login'}/>
         < Route element={<Registration/>} path={'/registration'}/>
         
{/* 
        <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>; */}
          </Routes>
          </Router>

          </AuthContext.Provider>

    </div>
    
  );
}

export default App;
