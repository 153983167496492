import Avatar from '@mui/material/Avatar';
import { Grid ,Paper,Divider,useMediaQuery} from '@mui/material';
import { useState,useEffect } from 'react';
import { serverURL,getData,postData } from '../Services/FetchNodeServices';
import StarsIcon from '@mui/icons-material/Stars';
import Header from '../Component/userinterface/Header';
import UpperHeader from '../Component/userinterface/UpperHeader';
import { useTheme } from "@mui/material/styles";
import loader from '../../src/loader.json'
import Lottie from "lottie-react";
import {Backdrop} from '@mui/material';
import Footer from '../Component/userinterface/Footer';


export default function ReviewsViewMore(){
    const [portfolioData, setPortFolioData] = useState([]);
    const [isGreenHovered, setIsGreenHovered] = useState(false);
    const [loading, setLoading] = useState(false);
     var theme=useTheme()
     const matchesMd=useMediaQuery(theme.breakpoints.down("md"))


    const fetchAllData = async () => {
        setLoading(true);
        const result = await getData('api-listing/1')
        if (result.status) {
            setPortFolioData(result.data)
         
        }
        setLoading(false)
    }
    
    useEffect(() => {
        
      if (!localStorage.getItem("token")) {
          createSession().then(() => {
              fetchAllData()
          })
      }
      fetchAllData()
    }, [])
    
      
    
      const createSession = async () => {
        setLoading(true)
          const body = { email: "9907026112", password: "12345678" }
          const result = await postData("login", body)
          if (result.token_status) {
              localStorage.setItem("token", result.data.user.jwt_token)
          }
          setLoading(false)
      }
    

    return(<div style={{fontFamily:'kanit'}}>

{matchesMd?<></> :
<Grid item xs={12}>
     <UpperHeader/>
     </Grid>
}
        
       
      

       <Grid item xs={12}>
     <Header/>
     </Grid>

        <Grid container spacing={3} style={{padding:15}}>

        <Grid item xs={12}>
            <span style={{fontFamily:'kanit',alignItems:'center',marginTop:20,display:'flex',fontSize:matchesMd?15:20,marginLeft:matchesMd?12:'1%',fontWeight:'bold',color:'#3e8e41'}}>Total Reviews: {portfolioData?.reviews?.Total_review}</span>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>




        {portfolioData?.reviews?.review_list?.map((item) => (
            
        <> 
      
        
           {/* <Grid item xs={12}  style={{ display: 'flex', alignItems: 'center' ,height: 'auto'}}>
               <Avatar src="/broken-image.jpg"  style={{width:35,height:35,display:'flex'}}/>
               <span style={{display:'flex',padding:10,fontWeight:'bold'}}>{item?.name}</span>
               <div  style={{width:'4%',height:'53%',color:'#fff',borderRadius:3,justifyContent:'center',alignItems:'center',display:'flex', background: isGreenHovered ? 'linear-gradient(to bottom, #3e8e41, #2c6e30 )' : 'linear-gradient(to bottom,#97cd9a, #3e8e41)'}} onMouseEnter={() => setIsGreenHovered(true)} onMouseLeave={() => setIsGreenHovered(false)}><StarsIcon fontSize="small"/><span style={{fontSize:15,padding:3}} >{item?.rating}</span> </div>
               <div style={{display:'flex',flexDirection:'column'}} >{item?.message}</div>
                </Grid>

                 <Grid item xs={12} style={{display:'flex',flexDirection:'column'}}>
                <span >{item?.message}</span>
                </Grid>

               /* <Grid item xs={12}>
                <Divider/>
                </Grid>  */}
               

<Grid item xs={12} style={{display:'flex',alignItems:'center'}}>
             
             <Avatar src="/broken-image.jpg"  style={{width:35,height:35,display:'flex'}}/>
             <span style={{display:'flex',padding:10,fontWeight:'bold'}}>{item?.name}</span>
            <div  style={{width:matchesMd?'12%':'4%',height:'53%',color:'#fff',borderRadius:3,justifyContent:'center',alignItems:'center',display:'flex', background: isGreenHovered ? 'linear-gradient(to bottom, #3e8e41, #2c6e30 )' : 'linear-gradient(to bottom,#97cd9a, #3e8e41)'}} onMouseEnter={() => setIsGreenHovered(true)} onMouseLeave={() => setIsGreenHovered(false)}><StarsIcon fontSize="small"/><span style={{fontSize:15,padding:3}} >{portfolioData?.reviews?.review_list[0]?.rating}</span> </div>
             
             </Grid>

             <Grid item xs={12} style={{display:'flex',alignItems:'center'}}>
             <span >{item?.message}</span>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Footer/>
            </Grid>
           
           
              
               
                </>

                
                 ))}
        
                
                 {/* <Grid item xs={12} style={{display:'flex',alignItems:'center'}}>
             
                  <Avatar src="/broken-image.jpg"  style={{width:35,height:35,display:'flex'}}/>
                 <span style={{display:'flex',padding:10,fontWeight:'bold'}}>{item?.reviews?.review_list[0]?.name}</span>
                 <div  style={{width:'4%',height:'53%',color:'#fff',borderRadius:3,justifyContent:'center',alignItems:'center',display:'flex', background: isGreenHovered ? 'linear-gradient(to bottom, #3e8e41, #2c6e30 )' : 'linear-gradient(to bottom,#97cd9a, #3e8e41)'}} onMouseEnter={() => setIsGreenHovered(true)} onMouseLeave={() => setIsGreenHovered(false)}><StarsIcon fontSize="small"/><span style={{fontSize:15,padding:3}} >{portfolioData?.reviews?.review_list[0]?.rating}</span> </div>
                 <div onClick={()=>navigate('/portfolioviewmore')} variant="outlined" style={{width:matchesMd?80:90,height:35,background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d )' : '#fff',display:'flex',color:isPinkHovered ?'white':'#e72e77',fontSize:12,alignItems:'center',justifyContent:'center',marginBottom:20,fontWeight:'bold',borderRadius:30,border:'solid 1px',cursor:'pointer',marginLeft:'auto'}} onMouseEnter={() => setIsPinkHovered(true)} onMouseLeave={() => setIsPinkHovered(false)}>View More</div>
                 </Grid>
  
                 <Grid item xs={12} style={{display:'flex',alignItems:'center'}}>
                 <span >{portfolioData?.reviews?.review_list[0]?.message}</span> 
              
              
                </Grid>
               
               
               
                    </Grid> */}



        





        </Grid>

          {/* Backdrop Loader */}
          <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <div style={{width:'20%'}}>
                <Lottie loop={true} animationData={loader}/>
                </div>
            </Backdrop>
        

        


    </div>)
}