import { Grid ,Paper,Skeleton,} from "@mui/material"
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from "react";
import Backdrop from '@mui/material/Backdrop';
import loader from '../../loader.json'
import Lottie from "lottie-react";
import { useMediaQuery} from '@mui/material';
import { useState,useEffect } from 'react';
import { getData,postData } from '../../Services/FetchNodeServices';
import { useTheme } from "@mui/material/styles"
import {MenuItem} from "@mui/material"
import { useNavigate } from "react-router-dom";


export default function CategoryComponent(){

  const [expanded, setExpanded] = useState('panel1');
    var theme=useTheme()
    var navigate=useNavigate()
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));
   
  const [loading, setLoading] = useState(false); 
  const[catgoryData,setCategoryData]=useState([])
  const [isShimmer, setIsShimmer] = useState(true);
  const [subCategoryList, setSubCategoryList] = useState({});
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
      
  
  
  
  const handleChange = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      await fetchAllSubCategory(panel);
    }
  }
   


  const CustomAccordion = styled((props) => (
    <MuiAccordion elevation={0} square {...props} />
  ))(() => ({
    width: matchesMd ? '80%' : '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: matchesMd ? 'flex-start' : 'center',
    marginBottom: 10,
  }));
      
    

  const CustomAccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'  }} />}
      {...props}
    />
  ))(() => ({
    flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
    },
  }));


  const CustomAccordionDetails = styled(MuiAccordionDetails)(() => ({}));





    const fetchPortfolio = async () => {
      setLoading(true);
      const result = await getData('api-category')
      if (result.status) {
       
        setCategoryData(result.data)
        setIsShimmer(false)
       
         // console.log(result.data)
      }
      setLoading(false);
  }


   
  const fetchAllSubCategory = async (category_id) => {
    setSubCategoryLoading(true);
    const result = await getData(`api-subcategory?category_id=${category_id}`);
    if (result.status) {
      setSubCategoryList(result.data);
    }
    setSubCategoryLoading(false);
  };

  // const fillAllSubCategory = () => {
  //   return subCategoryList?.map((item) => {
  //     return <MenuItem value={item.id}>{item.name}</MenuItem>;
  //   });
  // };
    
  
  
  
  
  const createSession = async () => {
      setLoading(true)
      const body = { email: "9907026112", password: "12345678" }
      const result = await postData("login", body)
      if (result.token_status) {
          localStorage.setItem("token", result.data.user.jwt_token)
      }
      setLoading(false);
  }
  
  useEffect(() => {
    if (!localStorage.getItem("token")) {
        createSession().then(() => {
          fetchPortfolio()
        })
    }
    fetchPortfolio()
}, [])


const showCategorySlideShimmer = () => (
  <Grid container spacing={2}>
    {[...Array(6)].map((_, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <Paper variant="outlined" style={{ padding: theme.spacing(2) }}>
          <Skeleton animation="wave" height={200} />
        </Paper>
      </Grid>
    ))}
  </Grid>
);

const handleNavigateSubcategory=(item)=>{
  navigate('/shopscreen',{state:{data:item}})
  localStorage.setItem("category", item.id)
 
 
}


      
     
       
  const imagesToShow = catgoryData.slice(0,6)

  const backgroundColors = [
    '#B33771', '#F97F51', '#BDC581', '#FC427B', '#218c74', '#227093'
];


// function Media(props) {
//     const { shimmer = false } = props;
// }


 return(<div style={{  height: '100%', overflowX: 'hidden',width:matchesMd?'auto':<></>,justifyContent:'center' }}> 

<Grid container spacing={0} style={{marginTop:10}}>


{loading ? (
                    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <div style={{width:'20%'}}>
                    <Lottie loop={true} animationData={loader}/>
                    </div>
                </Backdrop>
                ) : (
                 
                 
                 
                  imagesToShow.length ?(imagesToShow?.map((item, index) => (
            

<Grid item xs={12} sm={6} key={index} style={{ padding: theme.spacing(1)}}>
            
        
<CustomAccordion     value={item.id}
                  expanded={expanded === item.id}
                  onChange={handleChange(item.id)}
                  onClick={()=>handleNavigateSubcategory(item)}
                  style={{
                    backgroundColor: backgroundColors[index % backgroundColors.length],
                    display:'flex',
                   marginLeft:matchesMd?40:<></>
                  }}
                >
<CustomAccordionSummary style={{ backgroundColor: backgroundColors[index % backgroundColors.length] }}>
<Grid container alignItems="center" >
<Grid item xs={6} style={{ padding: 10 }}>
                    
<Typography style={{ color: '#fff', fontSize: '1.2rem' }}>{item.name} </Typography>
</Grid>
  {/* <Grid item xs={12}>
         <div><Typography style={{flexDirection:'column'}}>Bridal Makeup,Family Makeup</Typography></div> 
         </Grid> */}
        




         
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
       
     <img   src={item.photo} alt={item.name}style={{  width: matchesMd ? '70%' : '40%',
    height: 'auto',
    maxWidth: '100%', // Ensure image does not overflow its container
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 60,
    display: 'flex',
    marginLeft: 'auto',
    aspectRatio: 1 / 1,
    objectFit: 'cover', 
    }} 
                                            />
                                            
                                        </Grid>
                                        


          

        </Grid>
          </CustomAccordionSummary>
        
       
        
          <CustomAccordionDetails style={{ background: '#fff' }}>


                       {subCategoryLoading && expanded === item.id ? (
                      <Typography>Loading...</Typography>
                    ) : (
                      subCategoryList[item.id]?.map((subItem) => (
                        <Typography key={subItem.id}>{subItem.name}</Typography>
                      ))
                    )}             






                                </CustomAccordionDetails>

    
      
      

      </CustomAccordion>  

       

        

      </Grid>    

))

):(showCategorySlideShimmer())



               
               
                )}

       
        
        
        
        
        </Grid>

        


   </div>)
}



