import { Grid } from "@mui/material"
import { getData, postData, serverURL } from "../../Services/FetchNodeServices";
import { useEffect, useState } from "react";
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';





export default function CartImage(props){
    const[cartImage,setCartImage]=useState('')
    const [portfolioData, setPortFolioData] = useState([]);
    const [loading,setLoading]=useState(false)
    const [shimmer,setShimmer]=useState(true)
    const [Product, setProduct] = useState(() => {
        const storedItem = localStorage.getItem("cartItem");
        return storedItem ? JSON.parse(storedItem) : null;
    });


    
    const theme = useTheme();
   

   const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

    const CartImage=async()=>{
        const result= await getData('api-category?is_feature=1')
        if(result.status)
            {
                setCartImage(result.data)
            }
 }



 const fetchAllData = async () => {
    setLoading(true);
    const result = await getData('api-listing/1')
    if (result.status) {
      setPortFolioData(result.data)
        setShimmer(false)

     
    }
    setLoading(false)
}

useEffect(() => {
    
  if (!localStorage.getItem("token")) {
      createSession().then(() => {
          fetchAllData()
      })
  }
  fetchAllData()
}, [])



useEffect(() => {
    if (!Product) {
        fetchAllData();
    }
}, [Product]);

  


    const createSession=async()=>{
        const body={email: "9907026112",password:"12345678"}
        const result= await postData("login",body)
        if(result.token_status)
            {
                localStorage.setItem("token",result.data.user.jwt_token)

        }

    }

    useEffect(()=>{
        if(!localStorage.getItem("token")){
            createSession().then(()=>{
                CartImage()
            })
        }
        CartImage()
    },[])


   


    return(<div style={{width: matchesMd?'100%' :'100%',height:matchesMd?<></>:'100%'}}>
   {shimmer?<><Skeleton variant="rectangular" width={640} height={400} animation="wave" /></>:
  
  
<img src={Product?.main_photo|| portfolioData?.listing_data?.main_photo } style={{width:'100%',height:matchesMd?300:370,borderRadius:5}}/>
}
    </div>)
}