import { AppBar,Box,Toolbar,Button,Paper,useMediaQuery } from "@mui/material";
import logo from "../../assets/logo.png"
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { useState,useContext,useEffect  } from "react";
import ShowList from "./ShowList";
import { useTheme } from "@mui/material/styles";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DraftsIcon from '@mui/icons-material/Drafts';
import {Divider} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import VendorList from "./VendorList";
import EInviteList from "./EInviteList";
import ArticleList from "./ArticleList";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoginLogOutDropDown from "./LoginLogOutDropDown";
import user from "../../assets/1.webp"
import { postData,getData } from "../../Services/FetchNodeServices";




export default function Header()
{
  const { setCurrentUser } = useContext(AuthContext);
  const theme = useTheme();
  const navigate=useNavigate()
  const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

  const [isOpen,setIsOpen]=useState(false)
  const[catgoryData,setCategoryData]=useState([])
  const [vendorOpen,setVendorOpen]=useState(false)
  const [inviteOpen,setInviteOpen]=useState(false)
  const[status,setStatus]=useState(false)
  const[venueItem,setVenueItem]=useState(false)
  const[articleOpen,setArticleOpen]=useState(false)
  const[userProfileOpen,setUserProfileOpen]=useState(false)
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));


 

const handleLogOut = () => {
  localStorage.removeItem('userDetails');
  // Optionally navigate to a different page after logout
  navigate('/login'); // Replace '/login' with the path to your login page
}



  const drawerList=()=>{
    return(
      <paper>
      <div style={{padding:5, display:"flex", flexDirection:'column', justifyContent:"center",alignItems:"center",margin:10,}}>
      <img src={user}  style={{width:70,height:70,borderRadius:35}} />
          <div style={{fontFamily:'kanit',fontSize:16,fontWeight:'bold',marginTop:5,marginBottom:2,color:'black'}}> {userDetails?.name ? (
    <span>{userDetails.name}</span>
  ) : (
    <span></span>  // or you can simply render nothing with `null` or an empty string
  )}</div>
          <div style={{ fontFamily:'kanit',fontSize:12, fontWeight:'bold', color:'#636e72'}}>{userDetails?.email ? (
    <span>{userDetails.email}</span>
  ) : (
    <span></span>  // or you can simply render nothing with `null` or an empty string
  )}</div>
          <div style={{ fontFamily:'kanit',fontSize:12,fontWeight:'bold',color:'#636e72'}}>{userDetails?.phone ? (
    <span>{userDetails.phone}</span>
  ) : (
    <span></span>  // or you can simply render nothing with `null` or an empty string
  )}</div>
      </div>

      <div style={{ marginInline:'2px',}} >
          <List>
              <Divider/>
              <ListItem disablePadding>
                  <ListItemButton>
                      <ListItemIcon>
                          <DashboardIcon/>
                     </ListItemIcon>
                     {venueShow?.map((item, index) => (
                     <ListItemText  primary={<span  onClick={()=>handleNavigateSubcategory(item)}  style={{fontFamily:'kanit',fontSize:13,fontWeight:'bold'}}>{item.name}</span>}/>
                    ))
                  }
                 
                     </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                  <ListItemButton >
                      <ListItemIcon>
                          <DraftsIcon/>
                     </ListItemIcon>
                     <ListItemText primary={<span style={{fontFamily:'kanit',fontSize:13,fontWeight:'bold'}}>Vendors</span>}/>
                  </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                  <ListItemButton >
                      <ListItemIcon>
                          <DraftsIcon/>
                     </ListItemIcon>
                     <ListItemText primary={<span style={{fontFamily:'kanit',fontSize:13,fontWeight:'bold'}}>Shop</span>}/>
                  </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                  <ListItemButton >
                      <ListItemIcon>
                          <DraftsIcon/>
                     </ListItemIcon>
                     <ListItemText primary={<span style={{fontFamily:'kanit',fontSize:13,fontWeight:'bold'}}>E-Invites</span>}/>
                  </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                  <ListItemButton >
                      <ListItemIcon>
                          <DraftsIcon/>
                     </ListItemIcon>
                     <ListItemText primary={<span style={{fontFamily:'kanit',fontSize:13,fontWeight:'bold'}}>Articles</span>}/>
                  </ListItemButton>
              </ListItem>

           

            


              

              <Divider />
              <ListItem disablePadding>
                  <ListItemButton>
                      <ListItemIcon>
                          <DraftsIcon/>
                     </ListItemIcon>
                     <ListItemText primary={<span onClick={handleLogOut} style={{fontFamily:'kanit',fontSize:13,fontWeight:'bold'}}>Log Out</span>}/>
                  </ListItemButton>
              </ListItem>
          </List>

      </div>
  </paper>
    )
  }

  const searchBarComponent=()=>{
    return (
        <Paper
      component="form"
        sx={{ p: '2px 4px',display: 'flex', margin:1, alignItems: 'center', width:matchesMd?'80%':'20%',marginLeft:matchesMd?5:1}}
       >
       
         <InputBase
            sx={{ ml: 1, flex: 1 }}
          placeholder="Search Products Here..."
           inputProps={{ 'aria-label': 'search google maps' }}
          
         />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon  />
       </IconButton>
        
       </Paper>
   );

  }

  const secondarySearchBar=()=>{
    return(
      <Box sx={{ flexGrow: 1 }} >
      <AppBar style={{background:'#fff'}} position="static">
        <Toolbar style={{display:'flex',justifyContent:'space-between'}}>
        <MenuOutlinedIcon onClick={handleDrawer} style={{fontSize:30,color:'#000'}}/> 
         {searchBarComponent()}
         <div style={{ display:'flex',width:70,justifyContent:'space-between'}}>
           <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
           
           </div>
          
          
          </div>
        </Toolbar>
      </AppBar>
      <div>
      
      </div>
    </Box>
    )
  }


  const showCartDetails=()=>{
    setVendorOpen(false)
    setInviteOpen(false)
    setArticleOpen(false)
    setIsOpen(true)
  }

  const showVendorDetails=()=>{
     setIsOpen(false) 
    setVendorOpen(true)
    setInviteOpen(false)
    setArticleOpen(false)
  }

  const showUserProfile=()=>{
    setUserProfileOpen(true)
   
 }


 const hideUserProfile=()=>{
  setUserProfileOpen(false)
 
}

  const hideVendorDetails=()=>{
       
    setVendorOpen(false)
  }

  const hideCartDetails=()=>{
    setIsOpen(false)
  }

  const showInviteDetails=()=>{
    setIsOpen(false) 
    setVendorOpen(false)
   setInviteOpen(true)
   setArticleOpen(false)
 }

 const hideInviteDetails=()=>{
       
  
   setInviteOpen(false)
}


const showArticleDetails=()=>{
  setIsOpen(false) 
 setVendorOpen(false)
 setInviteOpen(false)
 setArticleOpen(true)
}

const handleShop=()=>{
  setIsOpen(false) 
  setVendorOpen(false)
  setInviteOpen(false)
  setArticleOpen(false)

}

const hideArticleDetails=()=>{
       
  
  setArticleOpen(false)
}

  const handleClose=()=>{
    setStatus(false)

  }

  const handleDrawer=()=>{
    setStatus(true)
  }

  
  const handleAllShop=()=>{
    navigate('/shopscreen')

  }


  const hideBoxMenu=()=>{
    hideCartDetails()
    hideVendorDetails()
    hideInviteDetails()
    hideArticleDetails()
    hideUserProfile()
    
  }

  
  const createSession = async () => {
    const body = { email: "9907026112", password: "12345678" }
    const result = await postData("login", body)
    if (result.token_status) {
        localStorage.setItem("token", result.data.user.jwt_token)
    }
}




  const fetchAllVenues = async () => {
    const result = await getData('api-listing?type=venue');
    if (result.status) {
       
        setVenueItem(result.data)
       
       
       
       
    }
}

useEffect(() => {
  if (!localStorage.getItem("token")) {
      createSession().then(() => {
        fetchAllVenues(venueItem)
      })
  }
  fetchAllVenues(venueItem)
}, [])



const handleAllVenue = () => {
  navigate('/shopscreen', {  categoryId: 1} );
}

const fetchPortfolio = async () => {
   
  const result = await getData('api-category')
  if (result.status) {
   
    setCategoryData(result.data)
   
   
     
  }
 
}

useEffect(() => {
  if (!localStorage.getItem("token")) {
      createSession().then(() => {
        fetchPortfolio()
      })
  }
  fetchPortfolio()
}, [])


const venueShow = catgoryData.slice(17)
console.log('cccccccccccccc',venueShow)

const handleNavigateSubcategory=(item)=>{
  navigate('/shopscreen',{state:{data:item}})
  localStorage.setItem("category", item.id)
 
 
}
  
 

   return(
    <Box sx={{ flexGrow: 1,position:'relative'}} onMouseLeave={()=>hideBoxMenu()}  >
         <AppBar style={{background:'#e72e77'}} position="static"   >
         <Toolbar style={{display:'flex',justifyContent:'space-evenly'}}  >
          <div   onClick={()=>navigate('/home')}><img src={logo}  style={{width:matchesMd?'30%':"30%",cursor:'pointer'}}  /></div>
         
            
            {matchesMd?<></>:
             <div  style={{cursor:'pointer',color:'#fff',display:'flex',marginLeft:90,fontFamily:'kanit'}}>
            <span><Button    onMouseOver={()=>hideBoxMenu()}  ></Button></span>
            {venueShow?.map((item, index) => (
            <span><Button   onMouseOver={()=>showCartDetails()}   onClick={()=>handleNavigateSubcategory(item)} style={{color:'#fff',fontWeight:'bold'}} > {item.name}</Button></span>
          ))
        }
            <span><Button   onMouseOver={()=>showVendorDetails()}   style={{color:'#fff',fontWeight:'bold'}}>Vendors</Button></span>
            <span><Button onMouseOver={()=>handleShop()} onClick={handleAllShop}  style={{color:'#fff',fontWeight:'bold'}}>Shop</Button></span>
            <span><Button  onMouseOver={()=>showInviteDetails()}   style={{color:'#fff',fontWeight:'bold'}}>E-Invites</Button></span>
            <span><Button  onMouseOver={()=>showArticleDetails()}  style={{color:'#fff',fontWeight:'bold'}}>Articles</Button></span>
           
           
            </div>

   }

<div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'space-evenly', alignItems: 'center' }}>
  <div>
    {!matchesMd ? (
      <>
        <SearchIcon 
          onClick={() => navigate('/searchingpage')} 
          style={{ display: "flex", margin: 14 }} 
        />
        
      </>
    ) : <div></div>}
  </div>

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0, marginTop: 15 }}  >
  <Avatar 
    src="/broken-image.jpg"  
    onClick={() => navigate('/login')} 
    style={{ width: 35, height: 35, cursor: 'pointer' }} 
  />
  {userDetails?.name ? (
    <span>{userDetails.name}</span>
  ) : (
    <span></span>  // or you can simply render nothing with `null` or an empty string
  )}
</div>


  <div>
    <ArrowDropDownIcon  
      onMouseOver={() => showUserProfile()} 
      
    />
  </div>
</div>



         


         </Toolbar>





         </AppBar>
         <div>
          {matchesMd?secondarySearchBar():<div></div>}
        
        </div>

        <Drawer
           anchor={'left'}
           open={status}
            onClose={handleClose}
          >
            {drawerList()}
          </Drawer>

         <ShowList  isOpen={isOpen}/>
         <VendorList  isOpen={vendorOpen}/>
         <EInviteList  isOpen={inviteOpen} />
         <ArticleList  isOpen={articleOpen} />
         <LoginLogOutDropDown isOpen={userProfileOpen} />
 </Box>
   )
}