import { Grid ,Divider} from "@mui/material"
import Header from "../Component/userinterface/Header"
import MainImage from "../Component/userinterface/MainImage"
import PopularSearches from "../Component/userinterface/PopularSearches"
import RealWeddingStories from "../Component/userinterface/RealWeddingStories"
import GalleryToLookFor from "../Component/userinterface/GalleryToLookFor"
import Footer from "../Component/userinterface/Footer"
import UpperHeader from "../Component/userinterface/UpperHeader"
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import CategoryComponent from "../Component/userinterface/CategoryComponent"
import { getData,postData,serverURL } from "../../src/Services/FetchNodeServices"
import { useState,useEffect } from "react"
import { useNavigate } from "react-router-dom"
import react,{Suspense,lazy} from 'react'

 const popular=lazy(()=>import('../Component/userinterface/PopularSearches'))



export default function Home(){
   const theme = useTheme();
 
   const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

   const [isPinkHovered, setIsPinkHovered] = useState(false);
     var navigate= useNavigate()


   
    
    
    
    
    return(<div style={{width:'100%',height:'100%',margin:0,padding:0,overflowX:'hidden',fontFamily:'kanit'}}>
      
     {matchesMd?<></> :
<Grid item xs={12}>
     <UpperHeader/>
     </Grid>
}
        
       
      

       <Grid item xs={12}>
     <Header/>
     </Grid>

    

       
     
<Suspense fallback={<div>Please Wait</div>}>
     <Grid item xs={12}>
     <PopularSearches/>
     </Grid>
     </Suspense > 

     <Grid item xs={12} style={{display:'flex',marginTop:matchesMd?25:35,fontWeight:'bold',fontSize:matchesMd?'1.7em':30,color:'#e72e77',marginLeft:matchesMd?<></>:10,padding:10}}>
    <span>  Categories </span>
     <span onClick={()=>navigate('/categoryviewmore')} variant="outlined" style={{width:matchesMd?80:90,height:35,background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d )' : '#fff',display:'flex',color:isPinkHovered ?'white':'#e72e77',fontSize:12,alignItems:'center',justifyContent:'center',marginBottom:10,fontWeight:'bold',borderRadius:30,border:'solid 1px',cursor:'pointer',marginLeft:'auto'}} onMouseEnter={() => setIsPinkHovered(true)} onMouseLeave={() => setIsPinkHovered(false)}>View More </span> 
        </Grid>

        <Divider/>


        <Grid item xs={12} style={{marginTop:30,marginLeft:matchesMd?<></>:3}}>
     <CategoryComponent/>
     </Grid>



   
    

    

    

     

 

     
{/* 
     <Grid item xs={12} style={{display:'flex',marginTop:matchesMd?25:35,fontWeight:'bold',fontSize:matchesMd?'0.7em':20,color:'grey',margin:matchesMd?<></>:10,padding:10}}>
           Real Wedding Stories
        </Grid>

        <Divider/>

        
     <Grid item xs={12} style={{marginTop:30,marginLeft:matchesMd?15:50}}>
     <RealWeddingStories/>
     </Grid>

     

     <Grid item xs={12} style={{display:'flex',marginTop:matchesMd?25:35,fontWeight:'bold',fontSize:matchesMd?'0.7em':20,color:'grey',margin:matchesMd?<></>:10,padding:10}}>
           Gallery To Look For
        </Grid>

        <Divider/>

        <Grid item xs={12} style={{marginTop:30,marginLeft:matchesMd?3:50,padding:matchesMd?<></>:<></>}}>
     <GalleryToLookFor/>
     </Grid> */}

     <Divider style={{marginTop:30}}/>
     
    
     


           

     

    
     <Grid item xs={12} style={{marginTop:20,display:'flex'}}>
         <Footer/>
            </Grid>



     
    </div>)
}