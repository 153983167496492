import { Grid,Button, DialogTitle} from "@mui/material"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RegisterNow from "../assets/RegisterNow.webp"

import 'react-loading-skeleton/dist/skeleton.css'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useMediaQuery } from "@mui/material";
import { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {useTheme} from "@mui/material";
import { postData } from "../Services/FetchNodeServices";
import Swal from "sweetalert2";




export default function Registration(){

    
    
    
   
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [open, setOpen] = useState(false);
    const [isPinkHovered, setIsPinkHovered] = useState(false);
    
    const navigate = useNavigate();
    const location = useLocation(); // Move this above where you access it
    const { open: isOpen, from = null, id = null, item = null } = location.state || {};
    console.log('frommmmmm',from)
    console.log('idddddddd',id)

    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (isOpen) {
            setOpen(true); // Open the dialog if 'isOpen' is true
        }
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
    };

    //   const handleSubmit = async () => {

    //     try {
    //         const body = { phone: number, name: name, email: email, password: password };
    //         const result = await postData('api-user', body);
    
    //         if (result && result.status) {
    //             // Store the user details in local storage
    //             localStorage.setItem("userDetails", JSON.stringify(body));

    
    //             Swal.fire({
    //                 position: "top-end",
    //                 icon: "success",
    //                 title: "You are registered now....",
    //                 showConfirmButton: false,
    //                 timer: 1500,
    //                 toast: true
    //             });
    
                
    
    //             // Navigate to profile after successful registration
    //             navigate("/profile");
    //         } else {
    //             console.error("Registration failed. API returned an invalid response.");
    //             Swal.fire({
    //                 position: "top-end",
    //                 icon: "error",
    //                 title: "Registration failed. Please try again.",
    //                 showConfirmButton: false,
    //                 timer: 1500,
    //                 toast: true
    //             });
    //         }
    //     } catch (error) {
    //         console.error("An error occurred during registration:", error);
    //         Swal.fire({
    //             position: "top-end",
    //             icon: "error",
    //             title: "An error occurred. Please try again.",
    //             showConfirmButton: false,
    //             timer: 1500,
    //             toast: true
    //         });
    //     }
    // };

    // const handleSubmit = async () => {
    //     try {
    //         const body = { phone: number, name: name, email: email, password: password };
    //         const result = await postData('api-user', body);
    
    //         if (result && result.status) {
    //             // Store user details in local storage
    //             localStorage.setItem("userDetails", JSON.stringify(body));
    
    //             Swal.fire({
    //                 position: "top-end",
    //                 icon: "success",
    //                 title: "You are registered now....",
    //                 showConfirmButton: false,
    //                 timer: 1500,
    //                 toast: true
    //             });
    
    //             // Navigate back to the original page
    //             if (from) {
    //                 // Use 'from' to navigate back to the cart page with the same ID
    //                 navigate(from); // 'from' already includes the ID
    //             } else {
    //                 // Navigate to profile if coming from another page
    //                 navigate("/profile");
    //             }
    //         } else {
    //             console.error("Registration failed. API returned an invalid response.");
    //             Swal.fire({
    //                 position: "top-end",
    //                 icon: "error",
    //                 title: "Registration failed. Please try again.",
    //                 showConfirmButton: false,
    //                 timer: 1500,
    //                 toast: true
    //             });
    //         }
    //     } catch (error) {
    //         console.error("An error occurred during registration:", error);
    //         Swal.fire({
    //             position: "top-end",
    //             icon: "error",
    //             title: "An error occurred. Please try again.",
    //             showConfirmButton: false,
    //             timer: 1500,
    //             toast: true
    //         });
    //     }
    // };

    const handleSubmit = async () => {
        try {
            const body = { phone: number, name: name, email: email, password: password };
            
            // Log the data you are sending
            console.log("Request body:", body);
            
            const result = await postData('api-user', body);
            
            // Log the result from the API
            console.log("API response:", result);
    
            if (result && result.status) {
                // Store user details in local storage
                localStorage.setItem("userDetails", JSON.stringify(body));
    
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "You are registered now....",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });
    
                // Navigate back to the original page
                if (from) {
                    // Pass back the same id and from state when navigating to the cart page
                    navigate(from, { state: { id, from } });
                } else {
                    // Navigate to profile if coming from another page
                    navigate("/profile");
                }
            } else {
                console.error("Registration failed. API returned an invalid response.");
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Registration failed. Please try again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });
            }
        } catch (error) {
            console.error("An error occurred during registration:", error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "An error occurred. Please try again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true
            });
        }
    };
    
    
   
    
    
    
    
    return( <Dialog
          maxWidth={"lg"}
          
         
         
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img src={RegisterNow} style={{width:'13%',height:'10%'}}/>
            </Grid>
            </DialogTitle>
          <DialogContent>

          <Grid item xs={12} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:10}}>
           <div style={{ display:'flex',alignItems:'center',background:'#fff',width:matchesMd?'85%':'65%', height:'28%',border:'solid 1px' }}>

            
           
           
            <PersonIcon style={{padding:10}}/>
            <input  value={name} onChange={(e) => setName(e.target.value)} style={{ height:50, width:'100%',  background:'transparent', border:'none', outline:'none', color:'#797979',fontSize:12,marginLeft:matchesMd?<></>:10,}} type="text" placeholder="Enter Name" />

           </div>

           </Grid>


           <Grid item xs={12} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:10}}>
           <div style={{ display:'flex',alignItems:'center',background:'#fff',width:matchesMd?'85%':'65%', height:'28%',border:'solid 1px' }}>

            
           
           <LocalPhoneIcon  style={{padding:10}}/>
            
            <input  value={number} onChange={(e) => setNumber(e.target.value)} style={{ height:50, width:'100%',  background:'transparent', border:'none', outline:'none', color:'#797979',fontSize:12,marginLeft:matchesMd?<></>:10,}} type="text" placeholder="Enter Number" />

           </div>

           </Grid>

           <Grid item xs={12} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:10}}>
           <div style={{ display:'flex',alignItems:'center',background:'#fff',width:matchesMd?'85%':'65%', height:'28%',border:'solid 1px' }}>

            
           
           <EmailIcon  style={{padding:10}}/>
            
            <input  value={email} onChange={(e) => setEmail(e.target.value)} style={{ height:50, width:'100%',  background:'transparent', border:'none', outline:'none', color:'#797979',fontSize:12,marginLeft:matchesMd?<></>:10,}} type="text" placeholder="Enter E-mail Id" />

           </div>

           </Grid>

           <Grid item xs={12} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:10}}>
           <div style={{ display:'flex',alignItems:'center',background:'#fff',width:matchesMd?'85%':'65%', height:'28%',border:'solid 1px' }}>

            
           
           <VpnKeyIcon  style={{padding:10}}/>
            
            <input  value={password} onChange={(e) => setPassword
              
              (e.target.value)} style={{ height:50, width:'100%',  background:'transparent', border:'none', outline:'none', color:'#797979',fontSize:12,marginLeft:matchesMd?<></>:10,}} type="text" placeholder="Enter Password" />

           </div>

           </Grid>

           <Grid item xs={12} style={{width:'100%',height:'30%',color:'#000',display:'flex',justifyContent:'center',alignItems:'center'}}>
          
          <div onClick={handleSubmit}  variant="outlined" style={{width:matchesMd?80:90,height:35,background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d )' : '#fff',display:'flex',color:isPinkHovered ?'white':'#e72e77',fontSize:12,alignItems:'center',justifyContent:'center',marginTop:30,fontWeight:'bold',borderRadius:30,border:'solid 1px',cursor:'pointer'}} onMouseEnter={() => setIsPinkHovered(true)} onMouseLeave={() => setIsPinkHovered(false)}>Submit</div>
            
            
            </Grid>



         
          </DialogContent>
          <DialogActions>
          
            <Button onClick={handleClose}>Close</Button>
            
          </DialogActions>
        </Dialog>)

  


    
    
    
    
    
    
    
    





}